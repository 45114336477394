import React from 'react';
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import Layout from "../layouts/pl"
import Fade from 'react-reveal/Fade';

// ICONS
import { FaWater } from 'react-icons/fa';
import { TiWeatherSunny } from 'react-icons/ti';
import { TiLocationOutline } from 'react-icons/ti';
import { FaSwimmingPool } from 'react-icons/fa';
import { IoIosFitness } from "react-icons/io";

const Oferta = (props) => {
    const data = useStaticQuery(graphql` 
        query {
            desktopImage: file(relativePath: { eq: "images/photos/oferta/oferta-main.jpg" }) {
                childImageSharp {
                    fluid(quality: 100){
                    ...GatsbyImageSharpFluid
                    }
                }   
            }
            mobileImage: file(relativePath: { eq: "images/photos/oferta/oferta-main-mobile.jpg" }) {
                childImageSharp {
                    fluid(quality: 50){
                        ...GatsbyImageSharpFluid
                    }
                }   
            }
        }
    `);

    const sources = [
        data.mobileImage.childImageSharp.fluid,
        {
            ...data.desktopImage.childImageSharp.fluid,
            media: `(min-width: 768px)`,
        },
    ];


    return (
        <Layout location={props.location}>
            <Fade up>
                <section>
                    <Img fluid={sources} alt="Dziwnowek apartamenty Amber" />
                    <h1 className="title">OFFER</h1>
                    <div className="content-container">
                        <h1>Perfect holidays and unforgettable impressions</h1>
                        <p>We invite you to spend your holidays in AMBER APARTMENTS. They are comfortable flats, which you can visit all year round. They are both located in new buildings in “Słoneczne Tarasy” - a residential area in Dziwnówek, only 250 meters from a beautiful and wide beach.</p>
                        <p>They are located on the first / third floor of a new building with an elevator.</p>
                        <p>The apartments are suitable for 4-person family. They have got a living room with an open kitchen, a bedroom, a bathroom and a balcony. There’s also a free private parking spot in the underground garage.</p>
                        <p>The flats are fully-furnished with an Internet connection (wi-fi) and air-conditioning for your use during hot summer days.</p>
                        <p>It is also possible to order breakfast and other meals that will be delivered to the doorstep of the apartment.</p>
                        <br />
                        <br />
                        <p>Dziwnówek is a calm amd small seaside village, perfect for a family vacation.</p>
                        <p>The village finds itself on the forefront of the Dziwnów Spit geological formation, between the Baltic Sea and Wrzosowo bay, which constitutes the northernmost part of Kamień bay.</p>
                        <p>The biggest touristic attraction is a sandy and wide beach and clean Baltic sea water. Bathing beaches are located among pine forests. Dziwnówek is considered as a spa village, children with respiratory infections or diseases are treated in sanatoria located here.</p>
                        <p>The area is popular for doing sports and other leisure activities, including water sports, cycling and hiking. There is a Windsurfing school and bike rentals nearby.</p>
                        <p>Well-located bike routes running along the coast through pine forests make it possible to completely calm down and relax.</p>
                        <br />

                    </div>
                </section>
            </Fade>

            <Fade up>
                <section className="facts">
                    <h2>Dziwnówek</h2>
                    <div>
                        <span>
                            <p>250 m</p>
                            <FaWater />
                            <p>to the seaside</p>
                            <span />
                        </span>
                        <span>
                            <p>200 m</p>
                            <TiLocationOutline />
                            <p>to the town centre</p>
                            <span />

                        </span>
                        <span>
                            <p>450 m</p>
                            <TiWeatherSunny />
                            <p>to lakes</p>
                            <span />

                        </span>
                        <span>
                            <p>300 m</p>
                            <FaSwimmingPool />
                            <p>to swimming pools</p>
                            <span />

                        </span>
                    </div>
                    <span className='divider' />
                    <h2>Dziwnów</h2>
                    <div>
                        <span>
                            <p>700 m</p>
                            <FaWater />
                            <p>to the seaside</p>
                            <span />
                        </span>
                        <span>
                            <p>300 m</p>
                            <TiLocationOutline />
                            <p>to the town centre</p>
                            <span />

                        </span>
                        <span>
                            <p>on site</p>
                            <FaSwimmingPool />
                            <p>swimming pool, jacuzzi and saunas</p>
                            <span />
                        </span>
                        <span>
                            <p>on site</p>
                            <IoIosFitness />
                            <p>fitness room</p>
                            <span />
                        </span>
                    </div>
                </section>
            </Fade>


        </Layout>
    )
}


export default Oferta
